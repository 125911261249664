import { graphql, Link } from 'gatsby'
import React from 'react'

import { Box, Flex } from '@rebass/emotion'

import {
  Button,
  HeroHeadline,
  LinkText,
  Paragraph,
  SubHeadline,
} from '../components/atoms'
import { ScrollSVGAnimator } from '../components/atoms/'
import { Layout } from '../components/layouts/Layout'
import { ConstrainedWidthContainer } from '../components/molecules'
import {
  GetInTouch,
  HomeGrowth,
  HomePlatform,
  SEO,
} from '../components/organisms'
import RiskImageMobile from '../images/Home_Graphic_DeepFraud_Mobile.svg'
import DeveloperImageMobile from '../images/Home_Graphic_DeveloperFirst_Mobile.svg'
import FullstackImageMobile from '../images/Home_Graphic_FullStackServices_Mobile.svg'
import TravelImageMobile from '../images/Home_Graphic_HonedInTravel_Mobile.svg'
import BookingPalLogo from '../images/Logo_bookingPal.svg'
import ChoiceLogo from '../images/Logo_Choice.svg'
import KigoLogo from '../images/Logo_Kigo.svg'
import RentpathLogo from '../images/Logo_Rentpath.svg'
import WyndhamLogo from '../images/Logo_Wyndham.svg'
import DeepFraudSVG from '../images/lottie/Home_Graphic_DeepFraud.json'
import DeveloperFirstSVG from '../images/lottie/Home_Graphic_DeveloperFirst.json'
import FullStackSVG from '../images/lottie/Home_Graphic_FullStackServices.json'
import HonedInTravelSVG from '../images/lottie/Home_Graphic_HonedInTravel.json'
import HomeVideo from '../images/YS_HomeVideo_200128.mp4'
import { Mq } from '../styles/settings/theme'
import styled from '../styles/styled'

const SectionImageContainer = styled(Box)`
  display: block;
  background: no-repeat center url("${props => props.mobileImageUrl}");
  background-size: contain;
  order: -1;
  min-height: 16rem;

  ${Mq.sm} {
    display: none;
  }
`

const SectionSVGContainer = styled(Box)`
  display: none;

  ${Mq.sm} {
    display: block;
    order: 0;
    min-height: 38rem;
    position: relative;
    transform: translateY(-150px);
  }
`

const VideoContainer = styled(Box)`
  overflow: hidden;
  height: auto;
`

const Video = styled.video`
  z-index: -1;
  position: relative;
`

const LogoBox = styled(Box)`
  text-align: center;
`

const StyledLinkText = styled(LinkText)`
  color: ${props => props.theme.colors.darkBlue};
`

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title={data.contentstackHomePage.title}
      description={data.contentstackHomePage.seo_description}
    />

    <ConstrainedWidthContainer>
      <Flex
        flexWrap="wrap"
        mb={[4, 0]}
        justifyContent="space-between"
        alignItems="center"
        mt={[0, -5]}
      >
        <Box width={[1, 1, 1, 5 / 12]} order={[1, 1, 1, 0]}>
          <Box py={[0]}>
            <HeroHeadline>
              {data.contentstackHomePage.hero_headline}
            </HeroHeadline>
            <Paragraph>{data.contentstackHomePage.hero_description}</Paragraph>
            <Link to={data.contentstackHomePage.explore_button_url}>
              <StyledLinkText>
                {data.contentstackHomePage.explore_button_text} ›
              </StyledLinkText>
            </Link>
          </Box>
        </Box>

        <Box width={[1, 1, 1, 7 / 12]} order={[0, 0, 0, 1]}>
          <VideoContainer>
            <Video
              loop={true}
              autoPlay={true}
              muted={true}
              playsInline={true}
              // @ts-ignore
              disableRemotePlayback={true}
              src={HomeVideo}
            />
          </VideoContainer>
        </Box>
      </Flex>

      <Flex
        flexWrap="wrap"
        justifyContent={['space-around', 'space-around', 'space-between']}
        alignItems="center"
        mb={[4, 5]}
        pt={[4, 5, 5, 5]}
      >
        <LogoBox flex={['0 0 auto', '1']} width={[1 / 3, 'auto']} mb={[4, 0]}>
          <img src={KigoLogo} alt="Kigo Logo" />
        </LogoBox>
        <LogoBox flex={['0 0 auto', '1']} width={[1 / 3, 'auto']} mb={[4, 0]}>
          <img src={ChoiceLogo} alt="Choice Logo" />
        </LogoBox>
        <LogoBox flex={['0 0 auto', '1']} width={[1 / 3, 'auto']} mb={[4, 0]}>
          <img src={WyndhamLogo} alt="Wyndham Logo" />
        </LogoBox>
        <LogoBox flex={['0 0 auto', '1']} width={[1 / 3, 'auto']} mb={[4, 0]}>
          <img src={RentpathLogo} alt="Rentpath Logo" />
        </LogoBox>
        <LogoBox flex={['0 0 auto', '1']} width={[1 / 3, 'auto']} mb={[4, 0]}>
          <img src={BookingPalLogo} alt="Booking Pal Logo" />
        </LogoBox>
      </Flex>

      <Box mb={[6, -6]}>
        <HomeGrowth />
      </Box>
    </ConstrainedWidthContainer>

    <HomePlatform />

    <ConstrainedWidthContainer pt={[1, 1, 7]}>
      <Flex flexWrap="wrap" my={[5]}>
        <SectionImageContainer
          mobileImageUrl={FullstackImageMobile}
          width={[1, 7 / 12]}
        />
        <SectionSVGContainer width={[1, 7 / 12]}>
          <ScrollSVGAnimator thresholdPercent={0.5} jsonObject={FullStackSVG} />
        </SectionSVGContainer>

        <Box width={[1, 5 / 12]}>
          <SubHeadline>
            {data.contentstackHomePage.full_stack_headline}
          </SubHeadline>
          <Box mb={[4]}>
            <Paragraph>
              {data.contentstackHomePage.full_stack_description}
            </Paragraph>
          </Box>

          <Link to={data.contentstackHomePage.explore_button_url}>
            <Button width={[1, 'auto']}>
              {data.contentstackHomePage.full_stack_learn_more_button_text}
            </Button>
          </Link>
        </Box>
      </Flex>

      <Flex flexWrap="wrap" mb={[5]}>
        <Box width={[1, 5 / 12]}>
          <SubHeadline>{data.contentstackHomePage.risk_headline}</SubHeadline>

          <Box mb={[4]}>
            <Paragraph>{data.contentstackHomePage.risk_description}</Paragraph>
          </Box>

          <Link to={data.contentstackHomePage.risk_learn_more_button_url}>
            <Button width={[1, 'auto']}>
              {data.contentstackHomePage.risk_learn_more_button_text}
            </Button>
          </Link>
        </Box>

        <SectionImageContainer
          mobileImageUrl={RiskImageMobile}
          width={[1, 7 / 12]}
        />
        <SectionSVGContainer width={[1, 7 / 12]}>
          <ScrollSVGAnimator thresholdPercent={0.5} jsonObject={DeepFraudSVG} />
        </SectionSVGContainer>
      </Flex>

      <Flex flexWrap="wrap" mb={[5]}>
        <SectionImageContainer
          mobileImageUrl={TravelImageMobile}
          width={[1, 7 / 12]}
        />
        <SectionSVGContainer width={[1, 7 / 12]}>
          <ScrollSVGAnimator
            thresholdPercent={0.5}
            jsonObject={HonedInTravelSVG}
          />
        </SectionSVGContainer>

        <Box width={[1, 5 / 12]}>
          <SubHeadline>{data.contentstackHomePage.travel_headline}</SubHeadline>
          <Box mb={[4]}>
            <Paragraph>
              {data.contentstackHomePage.travel_description}
            </Paragraph>
          </Box>

          <Link to={data.contentstackHomePage.travel_learn_more_button_url}>
            <Button width={[1, 'auto']}>
              {data.contentstackHomePage.travel_learn_more_button_text}
            </Button>
          </Link>
        </Box>
      </Flex>

      <Flex flexWrap="wrap" mb={[5, '-8%']}>
        <Box width={[1, 5 / 12]}>
          <SubHeadline>
            {data.contentstackHomePage.developer_headline}
          </SubHeadline>
          <Box mb={[4]}>
            <Paragraph>
              {data.contentstackHomePage.developer_description}
            </Paragraph>
          </Box>

          <a
            href={data.contentstackHomePage.developer_learn_more_url}
            target="_blank"
            rel="noreferrer"
          >
            <Button width={[1, 'auto']}>
              {data.contentstackHomePage.developer_learn_more_button_text}
            </Button>
          </a>
        </Box>

        <SectionImageContainer
          mobileImageUrl={DeveloperImageMobile}
          width={[1, 7 / 12]}
        />
        <SectionSVGContainer width={[1, 7 / 12]}>
          <ScrollSVGAnimator
            thresholdPercent={0.5}
            jsonObject={DeveloperFirstSVG}
          />
        </SectionSVGContainer>
      </Flex>
    </ConstrainedWidthContainer>

    <GetInTouch />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    contentstackHomePage {
      title
      seo_description
      hero_headline
      hero_description
      explore_button_text
      explore_button_url
      full_stack_headline
      full_stack_description
      full_stack_learn_more_button_text
      risk_headline
      risk_description
      risk_learn_more_button_text
      risk_learn_more_button_url
      travel_headline
      travel_description
      travel_learn_more_button_text
      travel_learn_more_button_url
      developer_headline
      developer_description
      developer_learn_more_button_text
      developer_learn_more_url
    }
  }
`
